import MDinput from '@/components/MDinput';
import DateTimePicker from '@/components/DSE/DateTimePicker';
import { validatorRequire } from '@/utils/validators';

export function getFormDefinition(vue) {
  return {
    id: {
      type: null,
      defaultValue: undefined
    },
    code: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 255,
        disabled: true,
        required: true,
        caption: 'configuration.code'
      },
      rules: [{ validator: validatorRequire }]
    },
    description: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 255,
        required: true,
        disabled: true
      },
      rules: [{ validator: validatorRequire }]
    },
    value: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 255,
        caption: 'configuration.value'
      }
    },
    fromDate: {
      type: DateTimePicker,
      defaultValue: '',
      props: {
        caption: 'configuration.fromDate',
        type: 'datetime',
        format: vue.$store.getters.dateTimeFormat
      }
    }
  };
}
export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  return item;
}
